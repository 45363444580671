import { graphql } from 'gatsby';
import { Col, Container, DangerouslySetInnerHtml, Layout, Row } from 'layout';
import React, { FC } from 'react';
import GatsbyImage from 'common/GatsbyImage';
import Banner from 'components/Banner';
import RelatedArticles from 'components/RelatedArticles';
import breakpoints from 'components/RelatedArticles/constants';
import Video from 'components/Video';
import SuccessfulSexPageNavigation from 'components/SuccessfulSexPageNavigation/SuccessfulSexPageNavigation';
import { ISuccessfulSexPageComponentProps } from './models';
import './SuccessfulSexPage.scss';

const SuccessfulSexPage: FC<ISuccessfulSexPageComponentProps> = ({
  data: {
    umbracoSuccessfulSex: {
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
      sideNavigation,
      banner,
      defaultCompositions,
      description,
      videoDiscription,
      title,
      lowerSectionBanner,
      lowestBanner,
      titleArticleHeading,
      videoSection,
      relatedArticlesSection: [
        {
          properties: { relatedArticlesReadMore },
        },
      ],
      url,
    },
    relatedArticles,
  },
}) => (
  <Layout
    defaultCompositions={defaultCompositions}
    seo={{
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
    }}
    className="successful-sex-documentation"
  >
    <Container fluid>
      {banner.map(({ properties: { title: bannerTitle, ...restBannerProperties } }) => (
        <Banner
          url={url}
          key={bannerTitle}
          title={bannerTitle}
          {...{ ...restBannerProperties, image: restBannerProperties.localImage }}
        />
      ))}
      <Container>
        <div className="container__inside">
          <Row>
            <Col lg="4" xl="4">
              <SuccessfulSexPageNavigation {...{ sideNavigation }} />
            </Col>

            <Col sm="12" md="12" lg="8" xl="8">
              <p className="successful-sex-documentation__description">{description}</p>
            </Col>

            {videoSection ? (
              <Col sm="12">
                <div className="successful-sex-documentation__video">
                  {videoSection.map(({ properties: { videoLink, localImage } }, videoIndex) => (
                    <Video
                      key={`video-${videoLink || videoIndex}`}
                      className="survey-page__video"
                      thumbnail={localImage}
                      videoURL={videoLink}
                    />
                  ))}
                </div>
              </Col>
            ) : null}

            <Col sm="12">
              <p className="successful-sex-documentation__description">{videoDiscription}</p>
            </Col>

            <Col sm="12">
              <div className="successful__image-wrapper">
                {lowerSectionBanner?.map(({ properties: { image, imageAltLabel } }) => (
                  <GatsbyImage
                    key={imageAltLabel}
                    className="header-images-row__image"
                    fluid={image?.childImageSharp.fluid}
                    alt={imageAltLabel}
                  />
                ))}
              </div>
            </Col>

            <Col sm="12">
              <div className="successful-sex__related-articles">
                <RelatedArticles
                  breakpoints={breakpoints}
                  data={relatedArticles.nodes}
                  title={titleArticleHeading}
                  readMoreText={relatedArticlesReadMore}
                  ariaLabel={relatedArticlesReadMore}
                  articlesCount={2}
                  sliceTitle={false}
                />
              </div>
            </Col>

            <Col sm="12">
              <DangerouslySetInnerHtml className="successful-sex__title" html={title} />
            </Col>

            <Col sm="12">
              <div className="successful-sex__lowest-banner-wrapper">
                {lowestBanner?.map(({ properties: { image, imageAltLabel } }) => (
                  <GatsbyImage
                    key={imageAltLabel}
                    className="header-images-row__image"
                    fluid={image?.childImageSharp.fluid}
                    alt={imageAltLabel}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Container>
  </Layout>
);

export const query = graphql`
  query SuccessfulSexPage($url: String = "", $relatedArticlesLinks: [String]) {
    umbracoSuccessfulSex(url: { eq: $url }) {
      url
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      description
      pageName
      videoDiscription
      title
      banner {
        properties {
          title
        }
      }
      sideNavigation {
        ...FragmentSuccessfulSexPageNavigation
      }
      lowerSectionBanner {
        ...FragmentImageWithAlt
      }
      lowestBanner {
        ...FragmentImageWithAlt
      }
      titleArticleHeading
      videoSection {
        ...FragmentVideo
      }
      relatedArticlesSection {
        properties {
          relatedArticlesTitle
          relatedArticlesReadMore
          articlesList {
            url
            name
            icon
            trashed
            udi
          }
        }
      }
    }
    relatedArticles: allUmbracoArticles(filter: { url: { in: $relatedArticlesLinks } }) {
      nodes {
        id
        title
        properties {
          title
          articleBody {
            structure
            properties {
              articleIntroductionText
            }
          }
          articleHeroBannerMainImageAltText
        }
        url
        localImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default SuccessfulSexPage;
